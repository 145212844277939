

// @mui material components
import Grid from '@mui/material/Grid';

// ACM React components
import MDBox from 'components/MDBox';

// ACM React example components
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import Footer from 'examples/Footer';

// Images
import { Card } from '@mui/material';
import MDInput from 'components/MDInput';
import MDButton from 'components/MDButton';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setContactsLoading } from 'store/actions/detailsActions';
import { getContacts } from 'store/actions/detailsActions';
import { editContacts } from 'store/actions/detailsActions';
import MDTypography from 'components/MDTypography';

function Overview() {
  //state values for all the inputs
  const [contactUsEmail, setContactUsEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [tiktok, setTiktok] = useState('');
  const [facebook, setFacebook] = useState('');
  const [instagram, setInstagram] = useState('');
  const [twitter, setTwitter] = useState('');
  const [youtube, setYoutube] = useState('');

  const media = useSelector((state) => state.details.media);
  const loading = useSelector((state) => state.details.loading);
  const fetched = useSelector((state) => state.details.fetched);
  const error = useSelector((state) => state.details.error);
  const dispatch = useDispatch();

  useEffect(() => {
    if(!fetched && !loading) {
      dispatch(setContactsLoading()); 
      dispatch(getContacts());
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
    

  useEffect(() => {
    if (fetched && !loading) {
      setContactUsEmail(media?.email);
      setPhoneNumber(media?.phone);
      setTiktok(media?.tiktok);
      setFacebook(media?.facebook);
      setInstagram(media?.instagram);
      setTwitter(media?.twitter);
      setYoutube(media?.youtube);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetched, loading]);



  //state value for the save button
  const handleSave = () => {
    const obj ={
      _id: media?._id,
      email: contactUsEmail,
      phone: phoneNumber,
      tiktok: tiktok,
      facebook: facebook,
      instagram: instagram,
      twitter: twitter,
      youtube: youtube,
    }
    dispatch(setContactsLoading()); 
    dispatch(editContacts(obj))
  };

  //update the state values for all the inputs
  const handleContactUsEmailChange = (e) => {
    setContactUsEmail(e.target.value);
  };

  const handlePhoneNumberChange = (e) => {
    setPhoneNumber(e.target.value);
  };

  const handleTiktokChange = (e) => {
    setTiktok(e.target.value);
  };

  const handleFacebookChange = (e) => {
    setFacebook(e.target.value);
  };

  const handleInstagramChange = (e) => {
    setInstagram(e.target.value);
  };

  const handleTwitterChange = (e) => {
    setTwitter(e.target.value);
  };

  const handleYoutubeChange = (e) => {
    setYoutube(e.target.value);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <MDBox position="relative" mb={5} alignItems={'center'}>
        <Card
          sx={{
            position: 'relative',
            // mt: -8,
            // mx: 3,
            py: 2,
            px: 2,
          }}
        >
          <Grid container alignItems="center">
            <Grid item sm={12} lg={12}>
              <MDBox p={1}>
                <MDInput
                  label="Contact us Email"
                  value={contactUsEmail}
                  fullWidth
                  onChange={handleContactUsEmailChange}
                />
              </MDBox>
              <MDBox p={1}>
                <MDInput
                  label="Phone number"
                  value={phoneNumber}
                  fullWidth
                  onChange={handlePhoneNumberChange}
                />
              </MDBox>
              <MDBox p={1}>
                <MDInput
                  label="Tiktok"
                  value={tiktok}
                  fullWidth
                  onChange={handleTiktokChange}
                />
              </MDBox>
              <MDBox p={1}>
                <MDInput
                  label={'Facebook'}
                  value={facebook}
                  fullWidth
                  onChange={handleFacebookChange}
                />
              </MDBox>
              <MDBox p={1}>
                <MDInput
                  label="Instagram"
                  value={instagram}
                  fullWidth
                  onChange={handleInstagramChange}
                />
              </MDBox>
              <MDBox p={1} width={'100%'}>
                <MDInput
                  label="Youtube"
                  value={youtube}
                  size="small"
                  fullWidth
                  onChange={handleYoutubeChange}
                />
              </MDBox>
              <MDBox p={1}>
                <MDInput
                  label="Twitter"
                  value={twitter}
                  fullWidth
                  onChange={handleTwitterChange}
                />
              </MDBox>
              {error && <MDTypography variant="caption" color="error">
            {error}
          </MDTypography>}
              <MDBox p={1} width={'100%'}>
                <MDButton
                  // component={Link}
                  // to={action.route}
                  fullWidth
                  variant="outlined"
                  size="medium"
                  color={'info'}
                  onClick={handleSave}
                  disabled={loading}
                >
                  Update
                </MDButton>
              </MDBox>
            </Grid>
          </Grid>
        </Card>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Overview;
