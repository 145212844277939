import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { clearData } from 'store/actions/userActions'

const Basic = () => {
    const dispatch = useDispatch()
    console.log('logout')
    useEffect(() => {
        console.log('logout')
        localStorage.removeItem('user')
        dispatch(clearData([]))
        window.location.href = "/"
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
  return (
    <></>
  )
}

export default Basic