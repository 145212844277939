import { EDIT_REWARD_LOADING } from "store/actions/Types"
import { EDIT_REWARD } from "store/actions/Types"
import { REWARDS_ERROR } from "store/actions/Types"
import { EDIT_REWARD_ERROR } from "store/actions/Types"
import { GET_REWARDS } from "store/actions/Types"
import { REWARDS_LOADING } from "store/actions/Types"

const initialState = {
    rewards: [],
    loading: false,
    error: null,
    editLoading: false,
    editError: null,
    edited: false,
}

export default function rewardsReducer(state = initialState, action) {
    switch (action.type) {
        case REWARDS_LOADING:
            return {
                ...state,
                loading: true,
                error: null,
                edited: false,
            }
        case GET_REWARDS:
            return {
                ...state,
                rewards: action.payload.data,
                loading: false,
                error: null,
                edited: false,
            }
        case EDIT_REWARD:
            return {
                ...state,
                editLoading: false,
                editError: null,
                edited: true,
            }
        case EDIT_REWARD_LOADING:
            return {
                ...state,
                editLoading: true,
                editError: null,
            }
            case REWARDS_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
                edited: false,
            }
        case EDIT_REWARD_ERROR:
            return {
                ...state,
                editLoading: false,
                editError: action.payload,
            }
        default:
            return state;
    }
}