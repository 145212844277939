import {combineReducers} from 'redux';
import gamesReducer from './gamesReducer';
import userReducer from './userReducer';
import distributionReducer from './distributionsReducers';
import detailsReducer from './detailsReducer';
import cashoutReducer from './cashoutReducer';
import giftShopReducer from './giftShopReducer';
import dashboardReducer from './dashboardReducer';
import liveInfoReducer from './liveInfoReducer';
import securityReducer from './securityReducer';
import rewardsReducer from './rewardsReducer';

export default combineReducers({
  user: userReducer,
  games: gamesReducer,
  distributions: distributionReducer,
  details: detailsReducer,
  cashouts: cashoutReducer,
  giftShops: giftShopReducer,
  dashboard: dashboardReducer,
  liveInfo: liveInfoReducer,
  security: securityReducer,
  levels: rewardsReducer,
});