import { CONTACTS_ERROR } from "store/actions/Types";
import { CONTACTS_LOADING } from "store/actions/Types";

const { GET_CONTACTS } = require("store/actions/Types");

const initialState = {
   media: { email: "",
    phone: "",
    tiktok: "",
    facebook: "",
    instagram: "",
    twitter: "",
    youtube: ""},
    loading: false,
    error: null,
    fetched: false,
}

const detailsReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_CONTACTS:
            return {
                ...state,
                media: action.payload.data,
                loading: false,
                error: null,
                fetched: true,
            }
        case CONTACTS_LOADING:
            return {
                ...state,
                loading: true,
                error: null,
                fetched: false,
            }
        case CONTACTS_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
                fetched: false,
            }
        default:
            return state;
    }
}

export default detailsReducer;
