import axios from 'axios';

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const post = (body, url, type, typeFail) => async (dispatch) => {
  console.log('post', `${BASE_URL}${url}`, body, type, typeFail);
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  try {
    const res = await axios.post(`${BASE_URL}${url}`, body, config);
    console.log('>>>>', res);
    dispatch({
      type,
      payload: res?.data,
    });
  } catch (err) {
    console.log('>>err', err);
    dispatch({
      type: typeFail,
      payload: err?.response?.data?.message,
    });
  }
};

export const get = (url, type, typeFail) => async (dispatch) => {
  console.log(url, type);
  try {
    const res = await axios.get(`${BASE_URL}${url}`);
    dispatch({
      type,
      payload: res?.data,
    });
  } catch (err) {
    dispatch({
      type: typeFail,
      payload: err?.response?.data?.message,
    });
  }
};

export const patch = (body, url, type, typeFail) => async (dispatch) => {
  console.log('url: ', url, '\nbody: ', body, '\ntype: ', type);
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  try {
    const res = await axios.patch(`${BASE_URL}${url}`, body, config);
    // console.log('the resss:    ', res);

    dispatch({
      type,
      payload: res.data,
    });
  } catch (err) {
    // console.log('err: ', err);
    dispatch({
      type: typeFail,
      payload: err?.response?.data.message,
    });
  }
};
