import moment from "moment";

export default function data(data) {
let users = [];
  for(let i = 0; i < data?.length; i++) {
    users.push({
      fullName: data[i].firstName + ' ' + data[i].lastName,
      coins: data[i].coins,
      xp: data[i].xp,
      money: data[i].money,
      active: data[i]?.active ? "true" : "false",
      lastLogin: data[i].lastLogin? new Date(data[i].lastLogin).getDate() : new Date().getDate(),
      subscription: moment(data[i].createdAt).format('YYYY-MM-DD'),
      _id: data[i]._id,
    })
 
  }
  let columns = [
    { Header: 'fullName', accessor: 'fullName', width: '15%', align: 'left' },
    { Header: 'coins', accessor: 'coins', align: 'left' },
    { Header: 'xp', accessor: 'xp', align: 'left' },
    { Header: 'money', accessor: 'money', align: 'left' },
    { Header: 'active', accessor: 'active', align: 'left' },
    { Header: 'lastLogin', accessor: 'lastLogin', align: 'left' },
    { Header: 'subscription', accessor: 'subscription', align: 'left' },
    { Header: 'userId', accessor: '_id', width:'10%', align: 'left'}
  ];

  return {
    columns,
    rows:users,
  };
}
