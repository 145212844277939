export default function data(data) {
const cashout = []

for(let i = 0; i < data?.length; i++) {
  cashout.push({
    fullName: data[i].fullName,
    country: data[i].country,
    amount: data[i].amount,
    option : data[i].option,
    status: data[i].status,
    _id: data[i]._id,
  })
}
    let columns = [
      { Header: 'Full Name', accessor: 'fullName', width: '20%', align: 'left' },
      { Header: 'Country', accessor: 'country', align: 'left' },
      { Header: 'Amount', accessor: 'amount', align: 'left' },
      { Header: 'Option', accessor: 'option', align: 'left' },
      { Header: 'Status', accessor: 'status', align: 'left' },
      { Header: 'ID', accessor: '_id', width:'20%', align: 'left' },
    ];
  
    return {
      columns,
      rows:cashout,
    };
  }
  