export default function data(data) {
    const cashout = []
    
    for(let i = 0; i < data?.length; i++) {
      cashout.push({
        level: data[i].level,
        name: data[i].name,
        cost: data[i].cost,
        _id: data[i]._id,
      })
    }
        let columns = [
          { Header: 'Level', accessor: 'level', width: '20%', align: 'left' },
          { Header: 'Name', accessor: 'name', width: '20%', align: 'left' },
          { Header: 'Cost', accessor: 'cost', align: 'left' },
          { Header: 'ID', accessor: '_id', width:'20%', align: 'left' },
        ];
      
        return {
          columns,
          rows:cashout,
        };
      }
      