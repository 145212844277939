export default function data(data) {

  let distribution = []

  for(let i = 0; i < data?.length; i++) {
    distribution.push({
      fullName: (data[i].firstName || data[i].lastName) ? `${data[i].firstName? data[i].firstName : ''} ${data[i]?.lastName? data[i].lastName : ''}` : "-",
      coins: data[i].coins,
      xp: data[i].xp,
      money: data[i].money,
    })
  }
    // const distribution = [
    //   {
    //     fullName: 'Steve Jobs',
    //     coins: '250',
    //     xp: '2',
    //     money: '121',
    //   },
    //   {
    //     fullName: 'John Doe',
    //     coins: '250',
    //     xp: '5',
    //     money: '121',
    //   },
    //   {
    //     fullName: 'Mark Zuckerberg',
    //     coins: '250',
    //     xp: '2',
    //     money: '121',
    //   },
    // ];
  
    let columns = [
      { Header: 'fullName', accessor: 'fullName', width: '45%', align: 'left' },
      { Header: 'coins', accessor: 'coins', align: 'left' },
      { Header: 'xp', accessor: 'xp', align: 'center' },
      { Header: 'money', accessor: 'money', align: 'center' },
    ];
  
    return {
      columns,
      rows:distribution,
    };
  }
  