import {
  UPDATE_LEVEL,
  GAME_CLEAR_DATA,
  GAME_ERROR,
  PLAY_GAME_LVL1,
} from '../actions/Types';

const initialState = {
  level1Result: null,
  winnings: null,
  level: 1,
  loading: false,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case PLAY_GAME_LVL1:
      const { combination, ...rest } = action.payload;

      return {
        ...state,
        level1Result: combination,
        winnings: rest,
      };

    case UPDATE_LEVEL:
      return {
        ...state,
        level: action.payload, //Check user.validated
      };

    case GAME_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case GAME_CLEAR_DATA:
      //to clear a specific variable form the state, we pass it in an array (created to avoid multiple customized cases)
      let variables = action.payload;
      let tempState = state;
      for (let i = 0; variables && i < variables.length; i++) {
        tempState[variables[i]] = initialState[variables[i]];
      }

      return variables?.length > 0 ? tempState : initialState;

    default:
      return state;
  }
};
