import { CASHOUT_ERROR } from "store/actions/Types";
import { EDIT_CASHOUT } from "store/actions/Types";
import { EDIT_CASHOUT_ERROR } from "store/actions/Types";
import { EDIT_CASHOUT_LOADING } from "store/actions/Types";
import { GET_CASHOUT } from "store/actions/Types";
import { CASHOUT_LOADING } from "store/actions/Types";

const initialState = {
    cashouts: [],
    cashout: {},
    loading: false,
    editLoading: false,
    editError: null,
    edited: false,
    error: null,
    empty: false,
}

export default function cashoutReducer(state = initialState, action) {
    switch (action.type) {
        case CASHOUT_LOADING:
            return {
                ...state,
                loading: true,
                error: null,
                edited: false,
            }
        case GET_CASHOUT:
            return {
                ...state,
                cashouts: action.payload.data,
                empty: action.payload.data.length === 0,
                loading: false,
                error: null,
            }
        case EDIT_CASHOUT_LOADING:
            return {
                ...state,
                editLoading: true,
                editError: null,
            }
            case EDIT_CASHOUT:
            return {
                ...state,
                editLoading: false,
                editError: null,
                edited: true,
            }
            case EDIT_CASHOUT_ERROR:
            return {
                ...state,
                editLoading: false,
                editError: action.payload,
            }

        case CASHOUT_ERROR:
            return {   
                ...state,
                error: action.payload,
                loading: false,
            }
        default:
            return state;
    }
}