export default function data(data) {
  const giftShop = []; 

  for(let i = 0; i < data?.length; i++) {
    giftShop.push({
      title: data[i].title,
      xp: data[i].xp,
      country: data[i].country,
      active: data[i]?.active? "active" : "inactive" ,
      // image: data[i].logo,
      _id: data[i]._id,
    })
  }

    let columns = [
      { Header: 'title', accessor: 'title', align: 'left' },
      { Header: 'country', accessor: 'country', align: 'left' },
      { Header: 'xp', accessor: 'xp', align: 'center' },
      { Header: 'status', accessor: 'active', align: 'center' },
        // { Header: 'image', accessor: 'image', width:'10%', align: 'center' },
      { Header: 'ID', accessor: '_id', width:'10%', align: 'center' },
    ];
  
    return {
      columns,
      rows:giftShop,
    };
  }
  