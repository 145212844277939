/**
/** 
  All of the routes for the Material Dashboard 2 React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 React layouts
import Dashboard from "layouts/dashboard";
import SignIn from "layouts/authentication/sign-in";
import Logout from "layouts/authentication/logout";

// @mui icons
import Icon from "@mui/material/Icon";
import Users from "layouts/users";
import Details from "layouts/details";
import Distributions from "layouts/distributions";
import GiftShop from "layouts/giftShop";
import Cashout from "layouts/cashout";
import LiveInfo from "layouts/liveInfo";
import SecurityControl from "layouts/securityControl";
import Levels from "layouts/levels";

const defaultRoutes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <Dashboard />,
  },
  {
    type: "collapse",
    name: "Users",
    key: "users",
    icon: <Icon fontSize="small">people</Icon>,
    route: "/users",
    component: <Users />,
  },
  {
    type: "collapse",
    name: "Distributions",
    key: "distributions",
    icon: <Icon fontSize="small">send</Icon>,
    route: "/distributions",
    component: <Distributions />,
  },
  {
    type: "collapse",
    name: "Levels",
    key: "levels",
    icon: <Icon fontSize="small">$</Icon>,
    route: "/levels",
    component: <Levels />,
  },
  {
    type: "collapse",
    name: "Details",
    key: "details",
    icon: <Icon fontSize="small">details</Icon>,
    route: "/details",
    component: <Details />,
  },
  {
    type: "collapse",
    name: "Cashout",
    key: "cashout",
    icon: <Icon fontSize="small">money</Icon>,
    route: "/cashout",
    component: <Cashout />,
  },
  {
    type: "collapse",
    name: "Gift Shop",
    key: "gift-shop",
    icon: <Icon fontSize="small">shop</Icon>,
    route: "/gift-shop",
    component: <GiftShop />,
  },
  {
    type: "collapse",
    name: "Live Info",
    key: "live-info",
    icon: <Icon fontSize="small">info</Icon>,
    route: "/live-info",
    component: <LiveInfo />,
  },
  {
    type: "collapse",
    name: "Security Control",
    key: "security-control",
    icon: <Icon fontSize="small">security</Icon>,
    route: "/security-control",
    component: <SecurityControl />,
  },
  // {
  //   type: "collapse",
  //   name: "Tables",
  //   key: "tables",
  //   icon: <Icon fontSize="small">table_view</Icon>,
  //   route: "/tables",
  //   component: <Tables />,
  // },
  // {
  //   type: "collapse",
  //   name: "Billing",
  //   key: "billing",
  //   icon: <Icon fontSize="small">receipt_long</Icon>,
  //   route: "/billing",
  //   component: <Billing />,
  // },
  // {
  //   type: "collapse",
  //   name: "RTL",
  //   key: "rtl",
  //   icon: <Icon fontSize="small">format_textdirection_r_to_l</Icon>,
  //   route: "/rtl",
  //   component: <RTL />,
  // },
  // {
  //   type: "collapse",
  //   name: "Notifications",
  //   key: "notifications",
  //   icon: <Icon fontSize="small">notifications</Icon>,
  //   route: "/notifications",
  //   component: <Notifications />,
  // },
  // {
  //   type: "collapse",
  //   name: "Profile",
  //   key: "profile",
  //   icon: <Icon fontSize="small">person</Icon>,
  //   route: "/profile",
  //   component: <Profile />,
  // },
  {
    type: "collapse",
    name: "Logout",
    key: "logout",
    icon: <Icon fontSize="small">logout</Icon>,
    route: "/authentication/logout",
    component: <Logout />,
  },
  // {
  //   type: "collapse",
  //   name: "Sign Up",
  //   key: "sign-up",
  //   icon: <Icon fontSize="small">assignment</Icon>,
  //   route: "/authentication/sign-up",
  //   component: <SignUp />,
  // },
];

const signInRoute = [
  
    {
    type: "collapse",
    name: "Sign In",
    key: "sign-in",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/authentication/sign-in",
    component: <SignIn />,
  },
  
]

export {defaultRoutes, signInRoute};
