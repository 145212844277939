

// @mui material components
import Grid from '@mui/material/Grid';
// ACM React components
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
// Images
import {Card, CircularProgress} from '@mui/material';
import MDInput from 'components/MDInput';
import { Modal } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MDTypography from 'components/MDTypography';
import { editLevelLoading } from 'store/actions/rewardsActions';
import { editLevel } from 'store/actions/rewardsActions';
// import Modal from '@mui/material/Modal';

function CustomLevelsModal({ open, setOpen }) {
  const [data, setData] = useState();
  
  const editLoading = useSelector((state) => state.levels.editLoading);
  const editError = useSelector((state) => state.levels.editError);
  const edited = useSelector((state) => state.levels.edited);

  const dispatch = useDispatch();

  // for some reasons, passing open to useState doesn't work
  // so I used useEffect to set the data
  useEffect(() => {
    if(open){
      setData(open)
    }
  }, [open])
  
  useEffect(() => {
    if(edited){
      setOpen(null)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [edited])

  //state value for the save button
  const handleSave = () => {
    dispatch(editLevelLoading());
    dispatch(editLevel(data));

  };

  const closeModal = () => {
    setOpen(null);
  };
  

  return (
    <Modal
      md={6}
      aria-labelledby='transition-modal-title'
      aria-describedby='transition-modal-description'
      open={open ? true : false}
      onClose={() => setOpen(null)}
      closeAfterTransition
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 9999,
       
      }}
    >
      <Card
        sx={{
          position: 'relative',
          // mt: -8,
          // mx: 3,
          py: 2,
          px: 2,
          width: 350,
          maxHeight: '90%',
          overflow: 'auto',
          // height:'auto',
        }}
      >
        <Grid container spacing={3} alignItems='center' height={'auto'}>
          <Grid item md={12} height={'auto'}>

          <MDBox p={1}>
              <MDInput
                label='Name'
                value={data?.name}
                fullWidth
                onChange={(e) => {
                  const newData = {...data}
                  newData.name = e.target.value
                  setData(newData)
              }}
              />
            </MDBox>

          <MDBox p={1}>
              <MDInput
                label='Level'
                value={data?.level}
                fullWidth
                editable={false}
                disabled={true}
              />
            </MDBox>

            <MDBox p={1}>
              <MDInput
                label='Total Tickets'
                value={data?.totalTickets}
                fullWidth
                inputProps={{
                  type: 'number',
                }}
                onChange={(e) => {
                    const newData = {...data}
                    newData.totalTickets = e.target.value
                    setData(newData)
                }}
              />
            </MDBox>

            <MDBox p={1}>
              <MDInput
                label='Cost'
                value={data?.cost}
                fullWidth
                onChange={(e) => {
                    const newData = {...data}
                    newData.cost = e.target.value
                    setData(newData)
                }}
              />
            </MDBox>

           

           {data?.rewards?.map((item, index) =>
           <MDBox key={index} sx={{
            display: 'flex',
            flexDirection: (data?.level !== 3)?'row' : 'column',
            justifyContent: 'space-between',
           }} > 
           <MDBox p={1}>
              <MDInput
                 key={index}
                label={item.title}
                value={item?.amount}
                inputProps={{
                    type: 'number',
                  }}
                fullWidth
                onChange={(e) => {
                    const newData = {...data}
                    newData.rewards[index].amount = e.target.value
                    setData(newData)
                }}
              />
            </MDBox>
            {(data?.level !== 3) && <MDBox p={1}>
              <MDInput
                 key={index}
                label={`Total Tickets`}
                value={item?.totalTickets}
                inputProps={{
                    type: 'number',
                  }}
                fullWidth
                onChange={(e) => {
                    const newData = {...data}
                    newData.rewards[index].totalTickets = e.target.value > 0 ? e.target.value : 0
                    setData(newData)
                }}
              />
            </MDBox>}
            </MDBox>) }
            
            {editError && <MDTypography variant="caption" color="error">
            {editError}
          </MDTypography>}
            <Grid p={1} width={'100%'} display='flex'
                justifyContent='space-between'>
                  <MDButton
                // component={Link}
                // to={action.route}
                variant='outlined'
                size='medium'
                color={'error'}
                onClick={closeModal}
                disabled={editLoading}
              >
                Cancel
              </MDButton>
            {editLoading? <CircularProgress color={'info'} /> : <MDButton
                // component={Link}
                // to={action.route}
                variant='outlined'
                size='medium'
                color={'info'}
                onClick={handleSave}
                disabled={editLoading}
              >
                {editLoading? 'Loading...' : 'Update'}
              </MDButton >}
              
            </Grid>
          </Grid>
        </Grid>
      </Card>
    </Modal>
  );
}

export default CustomLevelsModal;
