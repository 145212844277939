import { EDIT_GIFT_SHOPS, EDIT_GIFT_SHOP_ERROR, EDIT_GIFT_SHOP_LOADING, GET_GIFT_SHOPS, GIFT_SHOP_ERROR, GIFT_SHOP_LOADING } from './Types';

const factory = require('./actionsFactory');

export const getGiftShops = (active) => factory.get(`gifts/getGifts?active=${active!==undefined?active:''}`, GET_GIFT_SHOPS, GIFT_SHOP_ERROR);

export const setGiftShopsLoading = () => async (dispatch) => {
    dispatch({
        type: GIFT_SHOP_LOADING,
    });
}

export const editGiftShopLoading = () => async (dispatch) => {
    dispatch({
        type: EDIT_GIFT_SHOP_LOADING,
    });
}

export const editGiftShop = (body) => factory.post(body, 'gifts/editGift', EDIT_GIFT_SHOPS, EDIT_GIFT_SHOP_ERROR);

export const addGiftShop = (body) => factory.post(body, 'gifts/addgift', EDIT_GIFT_SHOPS, EDIT_GIFT_SHOP_ERROR);