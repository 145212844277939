import { GIFT_SHOP_ERROR } from "store/actions/Types";
import { EDIT_GIFT_SHOPS } from "store/actions/Types";
import { EDIT_GIFT_SHOP_ERROR } from "store/actions/Types";
import { EDIT_GIFT_SHOP_LOADING } from "store/actions/Types";
import { GET_GIFT_SHOPS } from "store/actions/Types";
import { GIFT_SHOP_LOADING } from "store/actions/Types";

const initialState = {
    giftShops: [],
    loading : false,
    error: null,
    editLoading: false,
    editError: null,
    edited: false,
    empty: false,
}

export default function giftShopReducer(state = initialState, action) {
    switch (action.type) {
        case GIFT_SHOP_LOADING:
            return {
                ...state,
                loading: true,
                error: null,
                edited: false,
            }
        
        case GET_GIFT_SHOPS:
            return {
                ...state,
                giftShops: action.payload.data,
                empty: action.payload.data.length === 0,
                loading: false,
                error: null,
            }
        case EDIT_GIFT_SHOP_LOADING:
            return {
                ...state,
                editLoading: true,
                editError: null,
            }
            case EDIT_GIFT_SHOPS:
            return {
                ...state,
                editLoading: false,
                editError: null,
                edited: true,
            }
            case EDIT_GIFT_SHOP_ERROR:
            return {
                ...state,
                editLoading: false,
                editError: action.payload,
            }

        case GIFT_SHOP_ERROR:
            return {
                ...state,
                error: action.payload,
                loading: false,
            }
        default:
            return state;
    }
}