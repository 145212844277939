

// @mui material components
import Grid from '@mui/material/Grid';

// ACM React components
import MDBox from 'components/MDBox';

// ACM React example components
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import Footer from 'examples/Footer';

// Images
import { Card } from '@mui/material';
import MDButton from 'components/MDButton';
import { useEffect, useState } from 'react';
import MDTypography from 'components/MDTypography';
import DataTable from 'examples/Tables/DataTable';
import giftShopTableData from './data/giftShopTableData';
import CustomGiftShopModal from './popUp/CustomGiftShopModal';
import { useDispatch, useSelector } from 'react-redux';
import { setGiftShopsLoading } from 'store/actions/giftShopActions';
import { getGiftShops } from 'store/actions/giftShopActions';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

function Overview() {
  // const { columns, rows } = giftShopTableData();
  const [open, setOpen] = useState(null);
  const [rows, setRows] = useState([]);
  const [columns, setColums] = useState([]);
  const [status, setStatus] = useState({
    active: true,
    inactive: false,
    all: false,
  });

  const [changed, setChanged] = useState(false);

  const dispatch = useDispatch();

  const loading = useSelector((state) => state.giftShops?.loading);
  const giftShops = useSelector((state) => state.giftShops.giftShops);
  const edited = useSelector((state) => state.giftShops.edited);
  const empty = useSelector((state) => state.giftShops.empty);

  useEffect(() => {
    if (giftShops?.length > 0 || (giftShops?.length === 0 && empty)) {
      const { columns, rows } = giftShopTableData(giftShops);
      setColums(columns);
      setRows(rows);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [giftShops]);

  useEffect(() => {
    if ((!giftShops?.length > 0 || edited) && !loading && !open) {
      fetchData(getStatus(status));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [edited, open]);

  useEffect(() => {
    if (changed) {
      setChanged(false);
     fetchData(getStatus(status));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, changed]);

  const fetchData = (s) => {

    dispatch(setGiftShopsLoading());
    dispatch(getGiftShops(s));
  };

  const openCustomModal = (data) => {
    // find in the cashouts array the cashout with the same _id as the data._id
    const giftShop = giftShops?.find((item) => item._id === data._id);
    setOpen(giftShop);
  };

  const handleStatus = (e, s) => {
    if(!status[s]){
      setStatus({
      active: false,
      inactive: false,
      all: false,
      [s]: true,
    });
    setChanged(true);
    }

  };

  const getStatus = (status) => {
    if(status.active) return 'true'
    if(status.inactive) return 'false'
    if(status.all) return ''
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                flexDirection="row"
              >
                <MDTypography variant="h6" color="white">
                  Gift Shop List Table
                </MDTypography>
                <MDButton
                  style={{ backgroundColor: 'rgba(0,0,0,0)' }}
                  onClick={() => setOpen({ newGiftShop: true })}
                >
                  <MDTypography variant="h3" color="white">
                    +
                  </MDTypography>
                </MDButton>
              </MDBox>
              <MDBox
                display="flex"
                alignItems="right"
                justifyContent="flex-start"
                style={{ marginLeft: '20px' }}
              >
      
                  <FormControlLabel
                    control={
                      <Checkbox
                      checked={status?.active}
                        onChange={(e) => handleStatus(e, 'active')}
                      />
                    }
                    label="Active"
                  />
              
                <FormControlLabel
                  control={
                    <Checkbox
                    checked={status?.inactive}                     
                     onChange={(e) => handleStatus(e, 'inactive')}
                    />
                  }
                  label="Inactive"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={status?.all}
                      onChange={(e) => handleStatus(e, 'all')}
                    />
                  }
                  label="All"
                />
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  entriesPerPage={{
                    defaultValue: 10,
                    entries: ['5', '10', '15', '20'],
                  }}
                  canSearch
                  showTotalEntries
                  pagination={{
                    showPagination: true,
                    showPageSizeOptions: true,
                  }}
                  isSorted
                  noEndBorder
                  onRowClick={(d) => openCustomModal(d)}
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
      <CustomGiftShopModal open={open} setOpen={setOpen} />
    </DashboardLayout>
  );
}

export default Overview;
