

// @mui material components
import Card from "@mui/material/Card";

// ACM React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "store/actions/userActions";
import { setUsersLoading } from "store/actions/userActions";
import { CircularProgress } from "@mui/material";

function Basic() {
const [email, setEmail] = useState("");
const [password, setPassword] = useState("");
const [error, setError] = useState("");
const dispatch = useDispatch();

const loading = useSelector((state) => state.user.loading);
const token = useSelector((state) => state.user.token);

useEffect(() => {
  if(token){
    // window.location.href = "/admin/dashboard"
    // console.log("token", token)
  }

}, [token]);

const signIn = () => {
  setError("")
  const emailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if(emailRegex.test(email) && password.length > 6){
    dispatch(setUsersLoading())
   dispatch(loginUser({email, password}));
  } else{
    setError("Email or password is invalid")
  }
};


  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Sign in
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput type="email" label="Email" fullWidth onChange={(e)=> setEmail(e.target.value)} />
            </MDBox>
            <MDBox mb={2}>
              <MDInput type="password" label="Password" fullWidth onChange={(e)=> setPassword(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  signIn();
                }
              }}
              />
            </MDBox>
            <MDBox mt={4} mb={1}>
          {error && <MDTypography variant="caption" color="error">
            {error}
          </MDTypography>}
              {loading? <MDBox fullWidth alignItems='center'  justifyContent='center' display={'flex'}> <CircularProgress color={'info'} /> </MDBox>: <MDButton variant="gradient" color="info" fullWidth onClick={signIn} disabled={loading}>
                sign in
              </MDButton>}
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Basic;
