// @mui material components
import Grid from '@mui/material/Grid';

// ACM React components
import MDBox from 'components/MDBox';

// ACM React example components
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import Footer from 'examples/Footer';

// Images
import { Card, Checkbox, FormControlLabel, IconButton } from '@mui/material';
import MDTypography from 'components/MDTypography';
import DataTable from 'examples/Tables/DataTable';
import { useEffect, useState } from 'react';
import cashoutTableData from './data/cashoutTableData';
import CustomCashoutModal from './popUp/CustomCashoutModal';
import { useDispatch, useSelector } from 'react-redux';
import { setCashoutsLoading } from 'store/actions/cashoutActions';
import { getCashouts } from 'store/actions/cashoutActions';
import MDButton from 'components/MDButton';
import exportCSVFile from 'assets/theme/functions/exportCSVFile';
import { FileDownload } from '@mui/icons-material';

function Overview() {
  const [open, setOpen] = useState(null);
  const [rows, setRows] = useState([]);
  const [columns, setColums] = useState([]);
  const [status, setStatus] = useState({
    all: false,
    approved: true,
    pending: false,
    rejected: false,
  });
  const [changed, setChanged] = useState(false);

  const loading = useSelector((state) => state.cashouts.loading);
  const cashouts = useSelector((state) => state.cashouts.cashouts);
  const edited = useSelector((state) => state.cashouts.edited);
  const empty = useSelector((state) => state.cashouts.empty);

  const dispatch = useDispatch();

  useEffect(() => {
    if (cashouts.length === 0 && !loading && !edited) {
      fetchData(getStatus(status));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (edited && !open) {
      fetchData(getStatus(status));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [edited, open]);

  useEffect(() => {
    if (changed) {
      setChanged(false);
      fetchData(getStatus(status));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, changed]);

  useEffect(() => {
    if (cashouts.length > 0 || (cashouts.length === 0 && empty)) {
      const { columns, rows } = cashoutTableData(cashouts);
      setColums(columns);
      setRows(rows);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cashouts]);

  const fetchData = (s) => {
    dispatch(setCashoutsLoading());
    dispatch(getCashouts(s));
  };

  const openCustomModal = (data) => {
    // find in the cashouts array the cashout with the same _id as the data._id
    const cashout = cashouts?.find((item) => item._id === data._id);

    setOpen(cashout);
  };

  const handleStatus = (e, s) => {
    if (!status[s]) {
      setStatus({
        approved: false,
        pending: false,
        rejected: false,
        [s]: true,
      });
      setChanged(true);
    }
  };

  const getStatus = (status) => {
    if (status.approved) return 'approved';
    if (status.pending) return 'pending';
    if (status.rejected) return 'rejected';
    if (status.all) return '';
  };

  const exportCSV = () => {
    const csvData = cashouts.map((cashout) => {
      return {
        _id: cashout._id,
        fullName: cashout.fullName,
        phoneNumber: cashout.phoneNumber,
        amount: cashout.amount,
        country: cashout.country,
        option: cashout.option,
        createdAt: cashout.createdAt,
        status: cashout.status,
      };
    });
    const headers = [
      { label: '_id', key: '_id' },
      { label: 'fullName', key: 'fullName' },
      { label: 'phoneNumber', key: 'phoneNumber' },
      { label: 'amount', key: 'amount' },
      { label: 'country', key: 'country' },
      { label: 'option', key: 'option' },
      { label: 'createdAt', key: 'createdAt' },
      { label: 'status', key: 'status' },
    ];
    exportCSVFile(headers, csvData, 'cashouts');
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                flexDirection="row"
              >
                <MDTypography variant="h6" color="white">
                  Cashout Table
                </MDTypography>
                <MDBox display="flex" alignItems="center">
                  <MDButton
                    style={{ backgroundColor: 'rgba(0,0,0,0)' }}
                    onClick={exportCSV}
                  >
                    <IconButton fontSize="medium" color="white">
                      <FileDownload />
                    </IconButton>
                  </MDButton>
                  <MDButton
                    style={{ backgroundColor: 'rgba(0,0,0,0)' }}
                    onClick={() => setOpen({ newCashout: true })}
                  >
                    <MDTypography variant="h3" color="white">
                      +
                    </MDTypography>
                  </MDButton>
                </MDBox>
              </MDBox>
              <MDBox
                display="flex"
                alignItems="right"
                justifyContent="flex-start"
                style={{ marginLeft: '20px' }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={status?.all}
                      onClick={(e) => handleStatus(e, 'all')}
                    />
                  }
                  label="All"
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={status?.approved}
                      onClick={(e) => handleStatus(e, 'approved')}
                    />
                  }
                  label="Approved"
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={status?.pending}
                      onChange={(e) => handleStatus(e, 'pending')}
                    />
                  }
                  label="Pending"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={status?.rejected}
                      onChange={(e) => handleStatus(e, 'rejected')}
                    />
                  }
                  label="Rejected"
                />
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  entriesPerPage={{
                    defaultValue: 10,
                    entries: ['5', '10', '15', '20'],
                  }}
                  canSearch
                  showTotalEntries
                  pagination={{
                    showPagination: true,
                    showPageSizeOptions: true,
                  }}
                  isSorted
                  noEndBorder
                  onRowClick={(d) => openCustomModal(d)}
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
      <CustomCashoutModal open={open} setOpen={setOpen} />
    </DashboardLayout>
  );
}

export default Overview;
