import { CONTACTS_ERROR, CONTACTS_LOADING, GET_CONTACTS } from './Types';

const factory = require('./actionsFactory');

export const getContacts = () => factory.get('details/contact', GET_CONTACTS, CONTACTS_ERROR);

export const setContactsLoading = () => async (dispatch) => {
    dispatch({
        type: CONTACTS_LOADING,
    });
}

export const editContacts = (body) => factory.post(body, 'details/editContact', GET_CONTACTS, CONTACTS_ERROR);