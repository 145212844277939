import { EDIT_SECURITY, EDIT_SECURITY_ERROR, EDIT_SECURITY_LOADING, GET_SECURITY, SECURITY_ERROR, SECURITY_LOADING } from './Types';

const factory = require('./actionsFactory');

export const getSecurity = () => factory.get(`details/security`, GET_SECURITY, SECURITY_ERROR);

export const setSecurity = (body) => factory.post(body, 'details/security/edit', EDIT_SECURITY, EDIT_SECURITY_ERROR);

export const setSecurityLoading = () => async (dispatch) => {
    dispatch({
        type: SECURITY_LOADING,
    });
}

export const securityEditLoading = () => async (dispatch) => {
    dispatch({
        type: EDIT_SECURITY_LOADING,
    });
}