

// @mui material components
import Grid from '@mui/material/Grid';
// ACM React components
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
// Images
import {Card, CircularProgress} from '@mui/material';
import MDInput from 'components/MDInput';
import { Modal } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useDispatch, useSelector } from 'react-redux';
import MDTypography from 'components/MDTypography';
import { editLiveInfoLoading } from 'store/actions/liveInfoActions';
import { editLiveInfo } from 'store/actions/liveInfoActions';
import { addLiveInfo } from 'store/actions/liveInfoActions';
// import Modal from '@mui/material/Modal';

function CustomInfoModal({ open, setOpen }) {
  const [data, setData] = useState();

  const editLoading = useSelector((state) => state.liveInfo.editLoading);
  const editError = useSelector((state) => state.liveInfo.editError);
  const edited = useSelector((state) => state.liveInfo.edited);

  const dispatch = useDispatch();

  // for some reasons, passing open to useState doesn't work
  // so I used useEffect to set the data
  useEffect(() => {
    if(open){
      setData(open)
    }
  }, [open])

  useEffect(() => {
    if(edited){
      setOpen(null)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [edited])
  

  const handleChange2 = (e, field) => {
    setData((prevData) => ({
      ...prevData,
      [field]: e.target.checked,
    }));
  };
  const handleChange = (e, key) => {
    setData((v) => ({ ...v, [key]: e.target.value }));
  };
  //state value for the save button
  const handleSave = () => {
    dispatch(editLiveInfoLoading());
    if(open.newLiveInfo){
      dispatch(addLiveInfo(data));
    } else {
      dispatch(editLiveInfo(data));
    }
    
  };

  const closeModal = () => {
    setOpen(null);
  };

  return (
    <Modal
      md={6}
      aria-labelledby='transition-modal-title'
      aria-describedby='transition-modal-description'
      open={open ? true : false}
      onClose={() => setOpen(null)}
      closeAfterTransition
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 9999,
      }}
    >
      <Card
        sx={{
          position: 'relative',
          // mt: -8,
          // mx: 3,
          py: 2,
          px: 2,
          width: 350,
          maxHeight: '90%',
          overflow: 'auto',
        }}
      >
        <Grid container spacing={3} alignItems='center'>
          <Grid item md={12}>
            <MDBox p={1}>
              <MDInput
                label='text'
                value={data?.text}
                fullWidth
                onChange={(e) => handleChange(e, 'text')}
              />
            </MDBox>
            <MDBox p={1}>
              <Grid item md={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={data?.active}
                      onChange={(e) => handleChange2(e, 'active')}
                      color='primary'
                    />
                  }
                  label='Active'
                />
              </Grid>
            </MDBox>
            {editError && <MDTypography variant="caption" color="error">
            {editError}
          </MDTypography>}
            <Grid p={1} width={'100%'} display='flex'
                justifyContent='space-between'>
                  <MDButton
                // component={Link}
                // to={action.route}
                variant='outlined'
                size='medium'
                color={'error'}
                onClick={closeModal}
                disabled={editLoading}
              >
                Cancel
              </MDButton>
            { editLoading? <CircularProgress color={'info'} /> : <MDButton
                // component={Link}
                // to={action.route}
                variant='outlined'
                size='medium'
                color={'info'}
                onClick={handleSave}
                disabled={editLoading}
              >
                {data?.liveInfo ? 'Save' : 'Update'}
              </MDButton>}
            </Grid>
          </Grid>
        </Grid>
      </Card>
    </Modal>
  );
}

export default CustomInfoModal;
