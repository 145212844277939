import { DISTRIBUTIONS_LOADING } from "store/actions/Types";
import { DISTRIBUTIONS_ERROR } from "store/actions/Types";
import { GET_DISTRIBUTIONS } from "store/actions/Types";

const initialState = {
    loading: false,
    error: null,
    distributions: [],
    xp_sum: 0,
    money_sum: 0,
    coins_sum: 0,
}

export default function distributionReducer(state = initialState, action) {
    switch (action.type) {
        case GET_DISTRIBUTIONS:
            return {
                ...state,
                loading: false,
                error: null,
                distributions: action.payload.data.list,
                xp_sum: action.payload.data.xp_sum,
                money_sum: action.payload.data.money_sum,
                coins_sum: action.payload.data.coins_sum,
            }
        case DISTRIBUTIONS_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            }

        case DISTRIBUTIONS_LOADING:
            return {
                ...state,
                loading: true,
                error: null,
            }

        default:
            return state;
    }
}