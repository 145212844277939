import { EDIT_REWARD, EDIT_REWARD_ERROR, EDIT_REWARD_LOADING, GET_REWARDS, REWARDS_ERROR, REWARDS_LOADING } from './Types';

const factory = require('./actionsFactory');

export const getLevels = () => factory.get(`levels`, GET_REWARDS, REWARDS_ERROR);

export const setLevelsLoading = () => async (dispatch) => {
    dispatch({
        type: REWARDS_LOADING,
    });
}

export const editLevel = (body) => factory.post(body, 'levels/edit', EDIT_REWARD, EDIT_REWARD_ERROR);

export const editLevelLoading = () => async (dispatch) => {
    dispatch({
        type: EDIT_REWARD_LOADING,
    });
}