import { DASHBOARD_ERROR } from "store/actions/Types";
import { GET_DASHBOARD } from "store/actions/Types";
import { DASHBOARD_LOADING } from "store/actions/Types";

const initialState = {
    loading: false,
    error: null,
    data: {},
}

export default function dashboardReducer(state = initialState, action) {
    switch (action.type) {
        case DASHBOARD_LOADING:
            return {
                ...state,
                loading: true,
                error: null,
            }
        case GET_DASHBOARD:
            return {
                ...state,
                data: action.payload.data,
                loading: false,
                error: null,
            }
        case DASHBOARD_ERROR:
            return {
                ...state,
                error: action.payload,
                loading: false,
            }
        default:
            return state;
    }
}