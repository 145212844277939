import { EDIT_SECURITY_LOADING } from 'store/actions/Types';
import { EDIT_SECURITY } from 'store/actions/Types';
import { EDIT_SECURITY_ERROR } from 'store/actions/Types';
import { GET_SECURITY } from 'store/actions/Types';
import { SECURITY_LOADING } from 'store/actions/Types';

const initialState = {
    activeStatus: null,
  security_id: null,
  activeVersion: null,
  version: null,
  securityError: null,
  securityLoading: false,
  editLoading: false,
  editError: null,
};

export default function securityReducer(state = initialState, action) {
  switch (action.type) {
    case SECURITY_LOADING:
      return {
        ...state,
        securityLoading: true,
        securityError: null,
      };
    case GET_SECURITY:
      return {
        ...state,
        version: action.payload?.data.version,
        security_id: action.payload?.data._id,
        activeStatus: action.payload?.data.activeVersion,
        securityLoading: false,
        securityError: null,
      };
    case EDIT_SECURITY_LOADING:
      return {
        ...state,
        editLoading: true,
        editError: null,
      };
    case EDIT_SECURITY:
      return {
        ...state,
        version: action.payload?.data.version,
        security_id: action.payload?.data._id,
        activeStatus: action.payload?.data.activeVersion,
        editLoading: false,
        editError: null,
      };
    case EDIT_SECURITY_ERROR:
      return {
        ...state,
        editLoading: false,
        editError: action.payload,
      };
    default:
      return state;
  }
}
