

// @mui material components
import Grid from '@mui/material/Grid';
// ACM React components
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
// Images
import {Card, Checkbox, CircularProgress, FormControlLabel} from '@mui/material';
import MDInput from 'components/MDInput';
import { Modal } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { editCashoutLoading } from 'store/actions/cashoutActions';
import { editCashout } from 'store/actions/cashoutActions';
import MDTypography from 'components/MDTypography';
// import Modal from '@mui/material/Modal';

function CustomCashoutModal({ open, setOpen }) {
  const [data, setData] = useState();

  const [status, setStatus] = useState({
    pending: false,
    approved: false,
    rejected: false,
  });
  

  const editLoading = useSelector((state) => state.cashouts.editLoading);
  const editError = useSelector((state) => state.cashouts.editError);
  const edited = useSelector((state) => state.cashouts.edited);

  const dispatch = useDispatch();

  // for some reasons, passing open to useState doesn't work
  // so I used useEffect to set the data
  useEffect(() => {
    if(open){
      setData(open)
      setStatus({
        pending: open.status === 'pending' ? true : false,
        approved: open.status === 'approved' ? true : false,
        rejected: open.status === 'rejected' ? true : false,
      })
    }
  }, [open])
  
  useEffect(() => {
    if(edited){
      setOpen(null)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [edited])

  //state value for the save button
  const handleSave = () => {
      dispatch(editCashoutLoading())
      let obj ={}
      if(status?.approved){
        obj = {
          status: 'approved',
        }
      }else if(status?.pending){
        obj = {
          status: 'pending',
        }
      }else if(status?.rejected){
        obj = {
          status: 'rejected',
        }
      }
      obj._id = data._id
      dispatch(editCashout(obj))

  };

  const closeModal = () => {
    setOpen(null);
  };

  const handleStatus = (e, key) => {
    // make only one checkbox checked
    if(!status[key])
      setStatus((v) => ({
        pending: false,
        approved: false,
        rejected: false,
        [key]: e.target.checked,
      }));
    }
  

  return (
    <Modal
      md={6}
      aria-labelledby='transition-modal-title'
      aria-describedby='transition-modal-description'
      open={open ? true : false}
      onClose={() => setOpen(null)}
      closeAfterTransition
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 9999,
      }}
    >
      <Card
        sx={{
          position: 'relative',
          // mt: -8,
          // mx: 3,
          py: 2,
          px: 2,
          width: 350,
          maxHeight: '90%',
          overflow: 'auto',
        }}
      >
        <Grid container spacing={3} alignItems='center'>
          <Grid item md={12}>

          <MDBox p={1}>
              <MDInput
                label='Name'
                value={data?.fullName}
                fullWidth
                editable={false}
                disabled={true}
              />
            </MDBox>

            <MDBox p={1}>
              <MDInput
                label='Phone number'
                value={data?.phoneNumber}
                fullWidth
                editable={false}
                disabled={true}
              />
            </MDBox>

            <MDBox p={1}>
              <MDInput
                label='Amount'
                value={data?.amount}
                fullWidth
                editable={false}
                disabled={true}
              />
            </MDBox>

            <MDBox p={1}>
              <MDInput
                label='Country'
                value={data?.country}
                fullWidth
                editable={false}
                disabled={true}
              />
            </MDBox>

            <MDBox p={1}>
              <MDInput
                label='Option'
                value={data?.option}
                fullWidth
                editable={false}
                disabled={true}
              />
            </MDBox>

            <MDBox px={1} pt={1}>
              <Grid item md={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={status?.approved}
                      onChange={(e) => handleStatus(e, 'approved')}
                      color='primary'
                    />
                  }
                  label='approved'
                />
              </Grid>
            </MDBox>

            <MDBox px={1}>
              <Grid item md={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={status?.pending}
                      onChange={(e) => handleStatus(e, 'pending')}
                      color='primary'
                    />
                  }
                  label='Pending'
                />
              </Grid>
            </MDBox>

            <MDBox px={1}>
              <Grid item md={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={status?.rejected}
                      onChange={(e) => handleStatus(e, 'rejected')}
                      color='primary'
                    />
                  }
                  label='Rejected'
                />
              </Grid>
            </MDBox>
            
            {editError && <MDTypography variant="caption" color="error">
            {editError}
          </MDTypography>}
            <Grid p={1} width={'100%'} display='flex'
                justifyContent='space-between'>
                  <MDButton
                // component={Link}
                // to={action.route}
                variant='outlined'
                size='medium'
                color={'error'}
                onClick={closeModal}
                disabled={editLoading}
              >
                Cancel
              </MDButton>
            {editLoading? <CircularProgress color={'info'} /> : <MDButton
                // component={Link}
                // to={action.route}
                variant='outlined'
                size='medium'
                color={'info'}
                onClick={handleSave}
                disabled={editLoading}
              >
                {editLoading? 'Loading...' : data?.newCashout ? 'Save' : 'Update'}
              </MDButton >}
              
            </Grid>
          </Grid>
        </Grid>
      </Card>
    </Modal>
  );
}

export default CustomCashoutModal;
