import { EDIT_LIVE_INFO, EDIT_LIVE_INFO_ERROR, EDIT_LIVE_INFO_LOADING, GET_LIVE_INFO, LIVE_INFO_ERROR, LIVE_INFO_LOADING } from './Types';

const factory = require('./actionsFactory');

export const getLiveInfo = (status) => factory.get(`liveInfo?active=${status}`, GET_LIVE_INFO, LIVE_INFO_ERROR);

export const setLiveInfoLoading = () => async (dispatch) => {
    dispatch({
        type: LIVE_INFO_LOADING,
    });
}

export const editLiveInfoLoading = () => async (dispatch) => {
    dispatch({
        type: EDIT_LIVE_INFO_LOADING,
    });
}

export const editLiveInfo = (body) => factory.post(body, 'liveInfo/edit', EDIT_LIVE_INFO, EDIT_LIVE_INFO_ERROR);

export const addLiveInfo = (body) => factory.post(body, 'liveInfo/add', EDIT_LIVE_INFO, EDIT_LIVE_INFO_ERROR);
