import { DASHBOARD_ERROR, DASHBOARD_LOADING, GET_DASHBOARD } from './Types';

const factory = require('./actionsFactory');

export const getDashboard = () => factory.get('dashboard', GET_DASHBOARD, DASHBOARD_ERROR);

export const setDashboardLoading = () => async (dispatch) => {
    dispatch({
        type: DASHBOARD_LOADING,
    });
}

