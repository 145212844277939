// @mui material components
import Grid from '@mui/material/Grid';

// ACM React components
import MDBox from 'components/MDBox';

// ACM React example components
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import Footer from 'examples/Footer';

// Images
import { Card, CircularProgress} from '@mui/material';
import MDTypography from 'components/MDTypography';
import DataTable from 'examples/Tables/DataTable';
import { useEffect, useState } from 'react';
import rewardsTableData from './data/levelsTableData';
import { useDispatch, useSelector } from 'react-redux';
import { setLevelsLoading } from 'store/actions/rewardsActions';
import { getLevels } from 'store/actions/rewardsActions';
import CustomLevelsModal from './popUp/CustomLevelsModal';

function Overview() {
  const [open, setOpen] = useState(null);
  const [rows, setRows] = useState([]);
  const [columns, setColums] = useState([]);

  const loading = useSelector((state) => state.levels.loading);
  const rewards = useSelector((state) => state.levels.rewards);
  const edited = useSelector((state) => state.levels.edited);

  const dispatch = useDispatch();

  useEffect(() => {
    if (rewards.length === 0 && !loading && !edited) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (edited && !open) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [edited, open]);

  useEffect(() => {
    if (rewards.length > 0 ) {
      const { columns, rows } = rewardsTableData(rewards);
      setColums(columns);
      setRows(rows);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rewards]);

  const fetchData = (s) => {
    dispatch(setLevelsLoading());
    dispatch(getLevels());
  };

  const openCustomModal = (data) => {
    // find in the cashouts array the cashout with the same _id as the data._id
    const reward = rewards?.find((item) => item._id === data._id);

    setOpen(reward);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                flexDirection="row"
              >
                <MDTypography variant="h6" color="white">
                  Levels & Cost
                </MDTypography>
              </MDBox>
              {loading? <MDBox width="100%" alignItems="center" justifyContent="center" display="flex" my={4} > <CircularProgress color={'info'} /> </MDBox>: <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  entriesPerPage={{
                    defaultValue: 10,
                    entries: ['5', '10', '15', '20'],
                  }}
                  canSearch
                  showTotalEntries
                  pagination={{
                    showPagination: true,
                    showPageSizeOptions: true,
                  }}
                  isSorted
                  noEndBorder
                  onRowClick={(d) => openCustomModal(d)}
                />
              </MDBox>}
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
      <CustomLevelsModal open={open} setOpen={setOpen} />
    </DashboardLayout>
  );
}

export default Overview;
