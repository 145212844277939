import axios from 'axios';
import { removeData, storeData } from './storage';

const setAuthToken = token => {
  if (token) {
    axios.defaults.headers.common['Authorization'] = "Bearer "+token;
    storeData('token',token);
  } else {
    delete axios.defaults.headers.common['Authorization'];
    removeData('token');
  }
};

export default setAuthToken;