import { storeData } from '../../utils/storage';
import {
  USER_ERROR,
  LOGOUT,
  SIGNUP_SUCCESS,
  LOGIN_SUCCESS,
  UPDATE_USER,
  USER_CLEAR_DATA,
  UPDATE_LEVEL,
  WINNINGS,
  GET_ALL_USERS,
  LOADING_USERS,
  EDIT_USER,
  EDIT_USER_ERROR,
  LOAD_EDIT_USER,
} from '../actions/Types';

const initialState = {
  user: null,
  xp: 0,
  money: 0,
  coins: 0,
  xpWinnings: 0,
  moneyWinnings: 0,
  coinsWinnings: 0,
  token: null,
  loading: false,
  error: null,
  users: [],
  loadingUser: false,
  editUserError: null,
  userEdited: false,
};

// the prefix is used to reuse types
const PREFIX = 'USER_';

const userDataObj = (payload) => {
  const { token, xp, money, coins, ...rest } = payload;
  let obj = { user: rest };
  if (token) {
    obj.token = token;
  }
  if (xp) {
    obj.xp = xp;
  }
  if (money) {
    obj.money = money;
  }
  if (coins) {
    obj.coins = coins;
  }
  return obj;
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {

  switch (action.type) {
    case SIGNUP_SUCCESS: //Completed step 1 of signup
    case UPDATE_USER:
      // delete action.payload.token;
      // storeData('user', action.payload);
      localStorage.setItem('user', JSON.stringify(action.payload));
      return {
        ...state,
        loading: false,
        error: null,
      ...userDataObj(action.payload)
      };

    case GET_ALL_USERS:
      return {
        ...state,
        users: action.payload.data,
        loading: false,
        error: null,
      };
    
      case LOADING_USERS:
        return {
          ...state,
          loading: true,
          error: null,
        };

    case UPDATE_LEVEL:
      return {
        ...state,
        level: action.payload, //Check user.validated
      };

    case LOGIN_SUCCESS: //Will Login Unconfirmed email
      // let { token, xp, money, coins, ...rest } = action.payload;
      // storeData('user', action.payload);
      localStorage.setItem('user', JSON.stringify(action.payload));
      return {
        ...state,
        ...userDataObj(action.payload),
        loading: false,
      };
    case USER_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload?.message,
      };

    case USER_CLEAR_DATA:
      //to clear a specific variable form the state, we pass it in an array (created to avoid multiple customized cases)
      let variables = action.payload;
      let tempState = state;
      for (let i = 0; variables && i < variables.length; i++) {
        tempState[variables[i]] = initialState[variables[i]];
      }
      return variables?.length > 0 ? tempState : initialState;

      case LOAD_EDIT_USER:
        return {
          ...state,
          loadingUser: true,
          editUserError: null,
          userEdited: false,
        }

    case EDIT_USER:
      return {
        ...state,
        loadingUser: false,
        editUserError: null,
        userEdited: true,
      }
    
      case EDIT_USER_ERROR:
        return {
          ...state,
          loadingUser: false,
          editUserError: action.payload,
          userEdited: false,
        }

    case WINNINGS:
      let xpWinnings = action.payload.xp - state.xp;
      let moneyWinnings = action.payload.money - state.money;
      let coinsWinnings = action.payload.coins - state.coins;

      return {
        ...state,
        xpWinnings,
        moneyWinnings,
        coinsWinnings,
        xp:action.payload.xp,
        money:action.payload.money,
        coins:action.payload.coins,
      };

    case LOGOUT:
      return initialState;

    default:
      return state;
  }
};
