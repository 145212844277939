// @mui material components
import Grid from '@mui/material/Grid';

// ACM React components
import MDBox from 'components/MDBox';

// ACM React example components
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import Footer from 'examples/Footer';

// Images
import {
  Card,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Icon,
} from '@mui/material';
import MDTypography from 'components/MDTypography';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MDButton from 'components/MDButton';
import MDInput from 'components/MDInput';
import { setSecurityLoading } from 'store/actions/securityActions';
import { setSecurity } from 'store/actions/securityActions';
import { securityEditLoading } from 'store/actions/securityActions';
import { getSecurity } from 'store/actions/securityActions';

function Overview() {
  const [version, setVersion] = useState('');
  const [status, setStatus] = useState(false);

  const loading = useSelector((state) => state.security.loading);
  const activeStatus = useSelector((state) => state.security.activeStatus);
  const selectedVersion = useSelector((state) => state.security.version);
  const securityId = useSelector((state) => state.security.security_id);
  const editLoading = useSelector((state) => state.security.editLoading);
  const editError = useSelector((state) => state.security.editError);
  const dispatch = useDispatch();

  useEffect(() => {
    if (version === '' || activeStatus === null) {
      dispatch(setSecurityLoading());
      dispatch(getSecurity());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!editLoading && !editError && activeStatus !== null) {
      setVersion(selectedVersion);
      setStatus(activeStatus);
    }
  }, [selectedVersion, activeStatus]);

  const save = () => {
    if (version !== '') {
      dispatch(securityEditLoading());
      dispatch(
        setSecurity({
          version: version,
          activeVersion: status,
          _id: securityId,
        })
      );
    }
  };

  const handleChange = (event) => {
    const { value } = event.target;

    // Regex to check if input is a decimal number between 0 and 9
    const regex = /^[0-9.]*$/;

    console.log(value, regex.test(value));
    // If the value is empty or passes the regex test, update the state
    if (value === '' || regex.test(value)) {
      setVersion(value);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                flexDirection="row"
              >
                <MDTypography variant="h6" color="white">
                  Security Control
                </MDTypography>
              </MDBox>
              <MDBox
                display="flex"
                alignItems="right"
                justifyContent="center"
                style={{ marginTop: '20px', marginBottom: '20px' }}
              >
                {loading ? (
                  <CircularProgress color={'info'} />
                ) : (
                  <MDBox
                    component="li"
                    display="flex"
                    justifyContent="space-between"
                    alignItems="flex-start"
                    bgColor={'grey-100'}
                    borderRadius="lg"
                    p={3}
                    mb={1}
                    mt={2}
                    width="95%"
                  >
                    <MDBox width="100%" display="flex" flexDirection="column">
                      <MDBox alignItems={'center'}>
                        <MDInput
                          placeholder="Version"
                          label="Version"
                          value={version}
                          onChange={handleChange}
                          inputProps={{
                            type: 'text',
                          }}
                        />
                      </MDBox>
                      <MDBox
                        display="flex"
                        justifyContent="space-between"
                        alignItems={{ xs: 'flex-start', sm: 'center' }}
                        flexDirection={{ xs: 'column', sm: 'row' }}
                        mb={2}
                      >
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={status}
                              onClick={(e) => setStatus(e.target.checked)}
                            />
                          }
                          label={'Hide this version'}
                        />
                      </MDBox>
                      {editLoading ? (
                        <CircularProgress color={'success'} size={20} />
                      ) : (
                        <MDButton
                          variant="text"
                          color={'success'}
                          onClick={save}
                        >
                          <Icon>save</Icon>&nbsp;Save
                        </MDButton>
                      )}
                    </MDBox>
                  </MDBox>
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Overview;
