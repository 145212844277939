import { EDIT_USER, EDIT_USER_ERROR, GET_ALL_USERS, LOADING_USERS, LOAD_EDIT_USER, LOGIN_SUCCESS, USER_CLEAR_DATA, USER_ERROR, WINNINGS } from './Types';
const factory = require('./actionsFactory');

// Signup User
export const signup = (data) =>
  factory.post(data, `api/users/`, 'SIGNUP_SUCCESS', 'USER_ERROR');

  export const updateData = (data) => async (dispatch) => {
    dispatch({
      type: WINNINGS,
      payload: data, //User
    });
  };
// Login User
export const loginUser = (data) =>
  factory.post(data, `users/login`, LOGIN_SUCCESS, USER_ERROR);

  export const clearData = (variables) => async (dispatch) => {
    dispatch({
      type: USER_CLEAR_DATA,
      payload: variables,
    });
  };
  
export const getAllUsers = () => factory.get(`users`, GET_ALL_USERS, USER_ERROR);

export const setUsersLoading = () => async (dispatch) => {
  dispatch({
    type: LOADING_USERS,
  });
};

export const editUser = (body) => 
  factory.post(body, `users/edituser`, EDIT_USER, EDIT_USER_ERROR);

export const loadEditUser = () => async (dispatch) => {
  dispatch({
    type: LOAD_EDIT_USER,
  });
}

export const getUserFromLocalStorage = () => async (dispatch) => {
  const user = JSON.parse(localStorage.getItem('user'));
  if (user) {
    dispatch({
      type: LOGIN_SUCCESS,
      payload: user,
    });
  }
}

  