// @mui material components
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';

// ACM React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';

// ACM React example components
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import Footer from 'examples/Footer';
import DataTable from 'examples/Tables/DataTable';

// Data
import usersTableData from 'layouts/users/data/usersTableData';
import CustomModal from './popUp/CustomUserModal';
import { useEffect, useState } from 'react';
import { getAllUsers } from 'store/actions/userActions';
import { setUsersLoading } from 'store/actions/userActions';
import { useDispatch, useSelector } from 'react-redux';
import { clearData } from 'store/actions/userActions';
import { CircularProgress } from '@mui/material';

function Users() {
  const [open, setOpen] = useState(null);
  const [columns, setColums] = useState([]);
  const [rows, setRows] = useState([]);

  const users = useSelector((state) => state.user.users);
  const loading = useSelector((state) => state.user.loading);
  const userEdited = useSelector((state) => state.user.userEdited);
  const dispatch = useDispatch();

  useEffect(() => {
    if (users.length === 0 && !userEdited) {
      getUsers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (userEdited && !open) {
      console.log('user edited');
      dispatch(clearData(['userEdited']));
      getUsers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userEdited, open]);

  const getUsers = () => {
    dispatch(setUsersLoading());
    dispatch(getAllUsers());
  };

  useEffect(() => {
    if (users.length > 0) {
      const { columns, rows } = usersTableData(users);
      setColums(columns);
      setRows(rows);
    }
  }, [users]);

  const openModal = (data) => {
    // find in users where id = data._id
    const user = users.find((user) => user._id === data._id);
    setOpen(user);
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                flexDirection="row"
              >
                <MDTypography variant="h6" color="white">
                  User List Table
                </MDTypography>

                {/* <MDButton style={{backgroundColor: 'rgba(0,0,0,0)'}} onClick={()=>setOpen({newUser: true})} >
                <MDTypography variant='h3' color='white'>
                 +
                </MDTypography>
                </MDButton> */}
              </MDBox>
              <MDBox py={3}>
                {loading ? (
                  <MDBox display={"flex"} alignItems={"center"} justifyContent={"center"}>
                    <CircularProgress color={'info'} />
                  </MDBox>
                ) : (
                  <DataTable
                    table={{ columns, rows }}
                    entriesPerPage={{
                      defaultValue: 10,
                      entries: ['5', '10', '15', '20'],
                    }}
                    canSearch
                    showTotalEntries
                    pagination={{
                      showPagination: true,
                      showPageSizeOptions: true,
                    }}
                    isSorted
                    noEndBorder
                    onRowClick={openModal}
                  />
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
      <CustomModal open={open} setOpen={setOpen} />
    </DashboardLayout>
  );
}

export default Users;
