export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const USER_ERROR = 'USER_ERROR';
export const LOGOUT = 'LOGOUT';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const USER_CLEAR_DATA = 'USER_CLEAR_DATA';
export const UPDATE_USER = 'UPDATE_USER';
export const WINNINGS = 'WINNINGS';
export const GET_ALL_USERS = 'GET_ALL_USERS';
export const LOADING_USERS = 'LOADING_USERS';
export const EDIT_USER = 'EDIT_USER';
export const EDIT_USER_ERROR = 'EDIT_USER_ERROR';
export const LOAD_EDIT_USER = 'LOAD_EDIT_USER';


// DISTRIBUTIONS
export const GET_DISTRIBUTIONS = 'GET_DISTRIBUTIONS';
export const DISTRIBUTIONS_ERROR = 'DISTRIBUTIONS_ERROR';
export const DISTRIBUTIONS_LOADING = 'DISTRIBUTIONS_LOADING';

// CONTACTS
export const GET_CONTACTS = 'GET_CONTACTS';
export const CONTACTS_ERROR = 'CONTACTS_ERROR';
export const CONTACTS_LOADING = 'CONTACTS_LOADING';

// CASHOUTS
export const GET_CASHOUT = 'GET_CASHOUT';
export const CASHOUT_ERROR = 'CASHOUT_ERROR';
export const CASHOUT_LOADING = 'CASHOUT_LOADING';
export const EDIT_CASHOUT = 'EDIT_CASHOUT';
export const EDIT_CASHOUT_LOADING = 'EDIT_CASHOUT_LOADING';
export const EDIT_CASHOUT_ERROR = 'EDIT_CASHOUT_ERROR';

// GIFT_SHOPS
export const GET_GIFT_SHOPS = 'GET_GIFT_SHOPS';
export const GIFT_SHOP_ERROR = 'GIFT_SHOP_ERROR';
export const GIFT_SHOP_LOADING = 'GIFT_SHOP_LOADING';
export const EDIT_GIFT_SHOPS = 'EDIT_GIFT_SHOPS';
export const EDIT_GIFT_SHOP_LOADING = 'EDIT_GIFT_SHOP_LOADING';
export const EDIT_GIFT_SHOP_ERROR = 'EDIT_GIFT_SHOP_ERROR';

// DASHBOARD
export const GET_DASHBOARD = 'GET_DASHBOARD';
export const DASHBOARD_ERROR = 'DASHBOARD_ERROR';
export const DASHBOARD_LOADING = 'DASHBOARD_LOADING';

// LIVE_INFO
export const GET_LIVE_INFO = 'GET_LIVE_INFO';
export const LIVE_INFO_ERROR = 'LIVE_INFO_ERROR';
export const LIVE_INFO_LOADING = 'LIVE_INFO_LOADING';
export const EDIT_LIVE_INFO = 'EDIT_LIVE_INFO';
export const EDIT_LIVE_INFO_LOADING = 'EDIT_LIVE_INFO_LOADING';
export const EDIT_LIVE_INFO_ERROR = 'EDIT_LIVE_INFO_ERROR';

// SECURITY
export const GET_SECURITY = 'GET_SECURITY';
export const SECURITY_ERROR = 'SECURITY_ERROR';
export const EDIT_SECURITY = 'EDIT_SECURITY';
export const EDIT_SECURITY_ERROR = 'EDIT_SECURITY_ERROR';
export const EDIT_SECURITY_LOADING = 'EDIT_SECURITY_LOADING';
export const SECURITY_LOADING = 'SECURITY_LOADING';

// REWARDS
export const GET_REWARDS = 'GET_REWARDS';
export const REWARDS_ERROR = 'REWARDS_ERROR';
export const REWARDS_LOADING = 'REWARDS_LOADING';
export const EDIT_REWARD_ERROR = 'EDIT_REWARD_ERROR';
export const EDIT_REWARD_LOADING = 'EDIT_REWARD_LOADING';
export const EDIT_REWARD = 'EDIT_REWARD';

// GAMES
export const UPDATE_LEVEL = 'UPDATE_LEVEL';
export const PLAY_GAME_LVL1 = 'PLAY_GAME_LVL1';
export const GAME_CLEAR_DATA = 'GAME_CLEAR_DATA';
export const GAME_ERROR = 'GAME_ERROR';
