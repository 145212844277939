// @mui material components
import Grid from '@mui/material/Grid';

// ACM React components
import MDBox from 'components/MDBox';

// ACM React example components
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import Footer from 'examples/Footer';

// Images
import { Card, Checkbox, CircularProgress, FormControlLabel } from '@mui/material';
import { useEffect, useState } from 'react';
import MDTypography from 'components/MDTypography';
import DataTable from 'examples/Tables/DataTable';
import distributionTableData from './data/distributionTableData';
import CustomDistributionModal from './popUp/CustomDistributionModal';
import { useDispatch, useSelector } from 'react-redux';
import { getDistributions } from 'store/actions/distributionActions';
import { setDistributionsLoading } from 'store/actions/distributionActions';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';
import MDButton from 'components/MDButton';
import moment from 'moment';
import { useMaterialUIController } from 'context';

function Overview() {
  const [controller] = useMaterialUIController();

  const { miniSidenav } = controller;

  const [open, setOpen] = useState(null);
  const [columns, setColums] = useState([]);
  const [rows, setRows] = useState([]);
  const [time, setTime] = useState({
    from: null,
    to: null,
  });
  const [timeChanged, setTimeChanged] = useState(false);
  const [timeSelection, setTimeSelection] = useState({
    day: false,
    week: false,
    month: false,
  });

  const loading = useSelector((state) => state.distributions.loading);
  const distributions = useSelector(
    (state) => state.distributions.distributions
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (distributions.length === 0 && !loading) {
      dispatch(setDistributionsLoading());
      dispatch(getDistributions());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // if (distributions.length > 0) {
    const { columns, rows } = distributionTableData(distributions);
    setColums(columns);
    setRows(rows);
    // }
  }, [distributions]);

  useEffect(() => {
    if (timeChanged) {
      let from = time.from
        ? moment(time?.from).format('MM-DD-YYYY')
        : undefined;
      let to = time.to ? moment(time?.to).format('MM-DD-YYYY') : undefined;
        if(timeSelection.day) {
          from = moment().subtract(1, 'days').format('MM-DD-YYYY');
          to = moment().format('MM-DD-YYYY');
        }
        if(timeSelection.week) {
          from = moment().subtract(7, 'days').format('MM-DD-YYYY');
          to = moment().format('MM-DD-YYYY');
        }
        if(timeSelection.month) {
          from = moment().subtract(1, 'months').format('MM-DD-YYYY');
          to = moment().format('MM-DD-YYYY');
        }
        
      dispatch(setDistributionsLoading());
      dispatch(getDistributions(from, to));
      setTimeChanged(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeChanged]);

  const handleTimeSelection = (e, s) => {
    setTimeSelection({
      day: false,
      week: false,
      month: false,
      [s]: !timeSelection[s],
    });
    setTime({
      from: null,
      to: null,
    });
    setTimeChanged(true);
  }

  const clear = () => {
    setTime({
      from: null,
      to: null,
    });
    setTimeSelection({
      day: false,
      week: false,
      month: false,
    });
    setTimeChanged(true);
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                flexDirection="row"
              >
                <MDTypography variant="h6" color="white">
                  Distributions Table
                </MDTypography>
              </MDBox>
              <MDBox
                mx={2}
                py={2}
                px={2}
                display="flex"
                alignItems="center"
                flexDirection={miniSidenav ? 'column' : 'row'}
              >
                <MDBox mr={miniSidenav ? 0 : 2} w={'auto'}>
                  <DatePicker
                    selected={time?.from}
                    onChange={(date) => {
                      setTime({
                        ...time,
                        from: date,
                      });
                      setTimeChanged(true);
                    }}
                    placeholderText="From"
                    maxDate={time?.to ? time?.to : undefined}
                  />
                </MDBox>

                <MDBox w={'auto'}>
                  <DatePicker
                    selected={time?.to}
                    onChange={(date) => {
                      setTime({
                        ...time,
                        to: date,
                      });
                      setTimeChanged(true);
                    }}
                    placeholderText="To"
                    minDate={time?.from ? time?.from : undefined}
                  />
                </MDBox>

                <MDBox ml={'10px'} mt={'2px'}>
                  <MDButton
                    color="white"
                    size="small"
                    alignSelf="center"
                    onClick={clear}
                  >
                    Clear
                  </MDButton>
                </MDBox>
                <FormControlLabel
                    control={
                      <Checkbox
                      checked={timeSelection?.day}
                        onClick={(e) => handleTimeSelection(e, 'day')}
                      />
                    }
                    label="Last day"
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                      checked={timeSelection?.week}
                        onClick={(e) => handleTimeSelection(e, 'week')}
                      />
                    }
                    label="Last week"
                  />
              
                <FormControlLabel
                  control={
                    <Checkbox
                    checked={timeSelection?.month}                     
                     onChange={(e) => handleTimeSelection(e, 'month')}
                    />
                  }
                  label="Last month"
                />
              </MDBox>
              <MDBox>
                {loading ? (
                  <MDBox
                    display={'flex'}
                    alignItems={'center'}
                    justifyContent={'center'}
                  >
                    <CircularProgress color={'info'} />
                  </MDBox>
                ) : (
                  <DataTable
                    table={{ columns, rows }}
                    entriesPerPage={{
                      defaultValue: 10,
                      entries: ['5', '10', '15', '20'],
                    }}
                    canSearch
                    showTotalEntries
                    pagination={{
                      showPagination: true,
                      showPageSizeOptions: true,
                    }}
                    isSorted
                    noEndBorder
                    onRowClick={(d) => setOpen(d)}
                  />
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
      <CustomDistributionModal open={open} setOpen={setOpen} />
    </DashboardLayout>
  );
}

export default Overview;
