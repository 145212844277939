export default function data(data) {
  const infoData = []; 

  for(let i = 0; i < data?.length; i++) {
    infoData.push({
      text: data[i].text,
      active: data[i]?.active? "active" : "inactive" ,
      _id: data[i]._id,
    })
  }

    let columns = [
      { Header: 'text', accessor: 'text', align: 'left', width:'30%' },
      { Header: 'status', accessor: 'active', align: 'center' },
      { Header: 'ID', accessor: '_id', align: 'center' },
    ];
  
    return {
      columns,
      rows:infoData,
    };
  }
  