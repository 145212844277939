import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './store/reducers';
import setAuthToken from './utils/setauthtoken';

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
      thunk: true,
    }),
});

store.subscribe(async () => {
  const currentToken = store.getState().user.token;
  if (currentToken) {
    setAuthToken(currentToken);
  }
});

export default store;
