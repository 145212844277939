// @mui material components
import Grid from "@mui/material/Grid";
// ACM React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
// Images
import { Card, CircularProgress } from "@mui/material";
import MDInput from "components/MDInput";
import { Modal } from "@mui/material";
import React, { useEffect, useState } from "react";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useDispatch, useSelector } from "react-redux";
import { loadEditUser } from "store/actions/userActions";
import { editUser } from "store/actions/userActions";
import MDTypography from "components/MDTypography";
import ReactDatePicker from "react-datepicker";
import countries from "utils/countries";
// import Modal from '@mui/material/Modal';

function CustomModal({ open, setOpen }) {
  const [data, setData] = useState();
  const dispatch = useDispatch();

  const userLoading = useSelector((state) => state.user.loading);
  const editUserError = useSelector((state) => state.user.editUserError);
  const userEdited = useSelector((state) => state.user.userEdited);

  // for some reasons, passing open to useState doesn't work
  // so I used useEffect to set the data
  useEffect(() => {
    if (open) {
      setData(open);
    }
  }, [open]);

  useEffect(() => {
    if (userEdited) {
      setOpen(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userEdited]);

  const handleChange2 = (e, field) => {
    setData((prevData) => ({
      ...prevData,
      [field]: e.target.checked,
    }));
  };
  const handleChange = (e, key) => {
    setData((v) => ({ ...v, [key]: e.target.value }));
  };
  //state value for the save button
  const handleSave = () => {
    let obj = {
      firstName: data?.firstName,
      lastName: data?.lastName,
      email: data?.email,
      phone: data?.phone,
      countryName: data?.countryName,
      dateOfBirth: data?.dateOfBirth,
      coins: data?.coins,
      xp: data?.xp,
      money: data?.money,
      active: data?.active,
      _id: data?._id,
    };
    dispatch(loadEditUser());
    if (open) {
      dispatch(editUser(obj));
    } else {
      // dispatch(addUser(data))
    }
  };

  const closeModal = () => {
    setOpen(null);
  };

  function handleCountryChange(event) {
    setData((prevData) => ({
      ...prevData,
      countryName: event.target.value,
    }));
  }
  return (
    <Modal
      md={6}
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open ? true : false}
      onClose={() => setOpen(null)}
      closeAfterTransition
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        zIndex: 9999,
      }}
    >
      <Card
        sx={{
          position: "relative",
          // mt: -8,
          // mx: 3,
          py: 2,
          px: 2,
          width: 350,
          maxHeight: '90%',
          overflow: 'auto',
        }}
      >
        <Grid container spacing={3} alignItems="center">
          <Grid item md={12}>
            <MDBox p={1}>
              <MDInput
                label="First Name"
                value={data?.firstName}
                fullWidth
                onChange={(e) => handleChange(e, "firstName")}
              />
            </MDBox>
            <MDBox p={1}>
              <MDInput
                label="Last Name"
                value={data?.lastName}
                fullWidth
                onChange={(e) => handleChange(e, "lastName")}
              />
            </MDBox>
            <MDBox p={1}>
              <MDInput
                label="Email"
                value={data?.email}
                fullWidth
                onChange={(e) => handleChange(e, "email")}
              />
            </MDBox>
            <MDBox p={1}>
              <MDInput
                label="Phone Number"
                value={data?.phone}
                fullWidth
                onChange={(e) => handleChange(e, "phone")}
              />
            </MDBox>
            <MDBox p={1}>
              <MDInput
                label="coins"
                value={data?.coins}
                fullWidth
                onChange={(e) => handleChange(e, "coins")}
              />
            </MDBox>
            <MDBox p={1}>
              <MDInput
                label="Xp"
                value={data?.xp}
                fullWidth
                onChange={(e) => handleChange(e, "xp")}
              />
            </MDBox>
            <MDBox p={1}>
              <MDInput
                label="money"
                value={data?.money}
                fullWidth
                onChange={(e) => handleChange(e, "money")}
              />
            </MDBox>
            {/* <MDBox p={1}>
              <MDInput
                label="Country"
                value={data?.countryName}
                fullWidth
                onChange={(e) => handleChange(e, 'countryName')}
              />
            </MDBox> */}

            <MDBox p={1}>
              <ReactDatePicker
                selected={
                  data?.dateOfBirth ? new Date(data?.dateOfBirth) : null
                }
                onChange={(date) => {
                  setData((prevData) => ({
                    ...prevData,
                    dateOfBirth: date,
                  }));
                }}
                showYearDropdown
                scrollableYearDropdown
                dateFormatCalendar="MMMM"
                yearDropdownItemNumber={100}
                maxDate={
                  new Date(
                    new Date().setFullYear(new Date().getFullYear() - 18)
                  )
                }
                customInput={
                  <MDInput
                    label="Date of Birth"
                    value={data?.dateOfBirth}
                    fullWidth
                    editable={false}
                    disabled={true}
                  />
                }
              />
            </MDBox>
            <MDBox p={1}>
              <select
                style={{
                  width: "100%",
                  height: 48,
                  border: "1px solid #ced4da",
                  borderRadius: 6,
                  paddingLeft: 6,
                }}
                id="country"
                name="country"
                value={data?.countryName}
                onChange={handleCountryChange}
              >
                <option value="">--Select a country--</option>
                {countries.map((country) => (
                  <option key={country} value={country}>
                    {country}
                  </option>
                ))}
              </select>
            </MDBox>
            <MDBox p={1}>
              <Grid item md={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        data?.active === "true" || data?.active === true
                          ? true
                          : false
                      }
                      onChange={(e) => handleChange2(e, "active")}
                      color="primary"
                    />
                  }
                  label="Active"
                />
              </Grid>
              {editUserError && (
                <MDTypography variant="caption" color="error">
                  {editUserError}
                </MDTypography>
              )}
            </MDBox>
            <Grid
              p={1}
              width={"100%"}
              display="flex"
              justifyContent="space-between"
            >
              <MDButton
                // component={Link}
                // to={action.route}
                variant="outlined"
                size="medium"
                color={"error"}
                onClick={closeModal}
                disabled={userLoading}
              >
                Cancel
              </MDButton>
              {userLoading ? (
                <CircularProgress color={"info"} />
              ) : (
                <MDButton
                  // component={Link}
                  // to={action.route}
                  variant="outlined"
                  size="medium"
                  color={"info"}
                  onClick={handleSave}
                  disabled={userLoading}
                >
                  {data?.newUser ? "Save" : "Update"}
                </MDButton>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Card>
    </Modal>
  );
}

export default CustomModal;
