import { LIVE_INFO_ERROR } from "store/actions/Types";

const { EDIT_LIVE_INFO_LOADING } = require("store/actions/Types");
const { EDIT_LIVE_INFO_ERROR } = require("store/actions/Types");
const { EDIT_LIVE_INFO } = require("store/actions/Types");
const { GET_LIVE_INFO } = require("store/actions/Types");
const { LIVE_INFO_LOADING } = require("store/actions/Types");

const initialState = {
    liveInfo: [],
    loading: false,
    edited: false,
    editLoading: false,
    editError: null,
    error: null,
    empty: false,
};

const liveInfoReducer = (state = initialState, action) => {
    switch (action.type) {
        case LIVE_INFO_LOADING:
            return {
                ...state,
                loading: true,
            };
        case GET_LIVE_INFO:
            return {
                ...state,
                liveInfo: action.payload.data,
                loading: false,
                empty: action.payload.data.length === 0,
            };
        case LIVE_INFO_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case EDIT_LIVE_INFO_LOADING:
            return {
                ...state,
                editLoading: true,
            };
        case EDIT_LIVE_INFO:
            return {
                ...state,
                editLoading: false,
                edited: true,
            };
        case EDIT_LIVE_INFO_ERROR:
            return {
                ...state,
                editLoading: false,
                editError: action.payload,
            };
        default:
            return state;
    }
}

export default liveInfoReducer;