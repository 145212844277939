const exportCSVFile = (headers, items, fileName) => {
    const separator = ',';
    let csvContent = '';
    
    // Add header row to CSV content
    const headerRow = headers.map(header => header.label).join(separator);
    csvContent += headerRow + '\r\n';
    
    // Add item rows to CSV content
    items.forEach(item => {
      const itemRow = headers.map(header => {
        const field = item[header.key] || '';
        // Enclose field in quotes if it contains the separator character
        return field.includes(separator) ? `"${field}"` : field;
      }).join(separator);
      csvContent += itemRow + '\r\n';
    });
    
    // Create a temporary anchor element to download the CSV file
    const link = document.createElement('a');
    link.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvContent));
    link.setAttribute('download', fileName + '.csv');
    document.body.appendChild(link);
    
    // Trigger click event to download the CSV file
    link.click();
    
    // Remove temporary anchor element
    document.body.removeChild(link);
  };

  export default exportCSVFile;