import { DISTRIBUTIONS_ERROR, DISTRIBUTIONS_LOADING, GET_DISTRIBUTIONS } from "./Types";

const factory = require('./actionsFactory');

const distributionsAPI = (from, to) => {
    let URL = `distributions`;

    if (from || to) {
        URL += `?`;
        if (from) {
            URL += `from=${from}`;
        }
        if (to) {
            URL += `&to=${to}`;
        }
    }
    return URL;
}
    

export const getDistributions = (from, to) => factory.get(distributionsAPI(from, to), GET_DISTRIBUTIONS, DISTRIBUTIONS_ERROR);

export const setDistributionsLoading = () => async (dispatch) => {
    dispatch({
        type: DISTRIBUTIONS_LOADING,
    });
}