

// @mui material components
import Grid from '@mui/material/Grid';
// ACM React components
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
// Images
import {Card} from '@mui/material';
import MDInput from 'components/MDInput';
import { Modal } from '@mui/material';
import React, { useEffect, useState } from 'react';

function CustomDistributionModal({ open, setOpen }) {
  const [data, setData] = useState();

  // for some reasons, passing open to useState doesn't work
  // so I used useEffect to set the data
  useEffect(() => {
    if(open){
      setData(open)
    }
  }, [open])

  const closeModal = () => {
    setOpen(null);
  };

  return (
    <Modal
      md={6}
      aria-labelledby='transition-modal-title'
      aria-describedby='transition-modal-description'
      open={open ? true : false}
      onClose={() => setOpen(null)}
      closeAfterTransition
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 9999,
      }}
    >
      <Card
        sx={{
          position: 'relative',
          // mt: -8,
          // mx: 3,
          py: 2,
          px: 2,
          width: 350,
          maxHeight: '90%',
          overflow: 'auto',
        }}
      >
        <Grid container spacing={3} alignItems='center'>
   
          <Grid item md={12}>
            <MDBox p={1}>
              <MDInput
                label='Full Name'
                value={data?.fullName}
                fullWidth
                editable={false}
              />
            </MDBox>
            <MDBox p={1}>
              <MDInput
                label='Coins'
                value={data?.coins}
                fullWidth
                editable={false}
              />
            </MDBox>
            <MDBox p={1}>
              <MDInput
                label='Xp'
                value={data?.xp}
                fullWidth
                editable={false}
              />
            </MDBox>
            <MDBox p={1}>
              <MDInput
                label='Money'
                value={data?.money}
                fullWidth
                editable={false}
              />
            </MDBox>
            <Grid p={1} width={'100%'} display='flex'
                justifyContent='space-between'>
                  <MDButton
                // component={Link}
                // to={action.route}
                fullWidth
                variant='outlined'
                size='medium'
                color={'error'}
                onClick={closeModal}
              >
                Cancel
              </MDButton>
            </Grid>
          </Grid>
        </Grid>
      </Card>
    </Modal>
  );
}

export default CustomDistributionModal;
