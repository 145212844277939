import { CASHOUT_LOADING } from 'store/actions/Types';
import { CASHOUT_ERROR } from 'store/actions/Types';
import { GET_CASHOUT } from 'store/actions/Types';
import { EDIT_CASHOUT, EDIT_CASHOUT_ERROR, EDIT_CASHOUT_LOADING } from './Types';

const factory = require('./actionsFactory');

export const getCashouts = (status) => factory.get(`cashout/getcashouts?status=${status !== undefined? status : ''}`, GET_CASHOUT, CASHOUT_ERROR);

export const setCashoutsLoading = () => async (dispatch) => {
    dispatch({
        type: CASHOUT_LOADING,
    });
}

export const editCashoutLoading = () => async (dispatch) => {
    dispatch({
        type: EDIT_CASHOUT_LOADING,
    });
}

export const editCashout = (body) => factory.post(body, 'cashout/editcashout', EDIT_CASHOUT, EDIT_CASHOUT_ERROR);